import React from "react";
import PropTypes from "prop-types";
import request from "helpers/request";
import Flash from "utilities/flash";
import { Checkbox, FormGroup } from "react-bootstrap";
import BackCircleArrow from "components/icons/back_circle_arrow";
import styled from "styled-components";
import { FormContainer, Input, StyledButton } from "./styles";
import { SecondaryButton } from "@grnhse/seedling/lib/birch/components/buttons";
import PrimaryPalette from "@grnhse/seedling/lib/birch/colors";

class PasswordForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
    };

    this.requestThrottled = 429;
  }

  onChange = (event) => {
    this.setState({ password: event.target.value });
  };

  submitForm = (event) => {
    event.preventDefault();

    if (this.state.password.length <= 0) {
      Flash.setError("Please enter your password");
      return;
    }

    request({
      method: "post",
      url: this.props.sign_in_path,
      data: {
        user: {
          login: this.props.login,
          password: this.state.password,
        },
      },
    })
      .then((request, _event) => {
        if (request.data.redirect_to) {
          window.location = request.data.redirect_to;
        }
      })
      .catch((error) => {
        if (error.response.status === this.requestThrottled) {
          Flash.setError(
            "Sorry, you have made too many requests. Please try again later."
          );
        } else if (error.response.data.error) {
          Flash.setError(error.response.data.error);
        } else {
          Flash.setError("Sorry, we encountered an error. Please try again.");
        }
      });
  };

  redirectToIdP = (event) => {
    event.preventDefault();
    window.location = this.props.samlPath;
  };

  backCallback = (event) => {
    event.preventDefault();

    this.props.backCallback();
  };

  render() {
    return (
      <FormContainer id="password-form">
        <div className="header-text">
          <h2>On board and all-together.</h2>
        </div>
        <StyledForm data-provides="password-form" onSubmit={this.submitForm}>
          <BackToEmailFormGroup>
            <a href="#" onClick={this.backCallback} id="back-to-email-form">
              <BackCircleArrow title="Change Email" />

              <span>{this.props.login}</span>
            </a>
          </BackToEmailFormGroup>

          <FormGroup controlId="password" bsSize="large">
            <Input
              type="password"
              placeholder="Password"
              aria-label="Password"
              name="password"
              onChange={this.onChange}
              required
              autoFocus
            />
          </FormGroup>

          <FormGroup className="contain-floats" style={{ marginTop: "24px" }}>
            <StyledCheckbox className="pull-left" inline>
              <span>Keep me signed in</span>
            </StyledCheckbox>

            <a
              href={this.props.forgot_password_path}
              className="pull-right desktop-help-link"
            >
              Forgot your password?
            </a>
          </FormGroup>

          <FlexContainer>
            <FormGroup>
              <StyledButton type="submit">Sign in</StyledButton>
            </FormGroup>
            {this.props.samlPath && (
              <FormGroup style={{ marginTop: "0px" }}>
                <StyledSecondaryButton onClick={this.redirectToIdP}>
                  Continue to SSO
                </StyledSecondaryButton>
              </FormGroup>
            )}
            <FormGroup>
              <a
                href={this.props.forgot_password_path}
                className="mobile-help-link"
              >
                Forgot your password?
              </a>
            </FormGroup>
          </FlexContainer>
        </StyledForm>
      </FormContainer>
    );
  }
}

const StyledCheckbox = styled(Checkbox)`
  color: ${PrimaryPalette.lightEvergreen};
  accent-color: ${PrimaryPalette.darkGreen};
`;

const StyledForm = styled.form`
  position: relative;
  margin-top: 40px;
`;

const StyledSecondaryButton = styled(SecondaryButton)`
  border-radius: 12px;

  @media only screen and (max-width: 480px) {
    margin-top: 32px;
    width: 100%;
    text-align: center;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 32px 0;
  width: 100%;
  & > * > * {
    margin-right: 40px;
  }

  //mobile
  @media only screen and (max-width: 480px) {
    display: block;
  }
`;

const BackToEmailFormGroup = styled(FormGroup)`
  svg {
    vertical-align: middle;
    margin-right: 5px;
  }
`;

PasswordForm.propTypes = {
  login: PropTypes.string.isRequired,
  forgot_password_path: PropTypes.string.isRequired,
  sign_in_path: PropTypes.string.isRequired,
  backCallback: PropTypes.func.isRequired,
  samlPath: PropTypes.string,
};

export default PasswordForm;
