import React from "react";
import styled from "styled-components";
import { colors } from "@grnhse/seedling/lib/azalea/constants";
import PrimaryPalette from "@grnhse/seedling/lib/birch/colors";

export const FormContainer = styled.div`
  color: ${colors.evergreen};

  h2 {
    font-family: "Untitled Serif", serif;
    font-size: 70px;
    font-weight: 400;
    text-align: left;
    line-height: 73.5px;
    letter-spacing: -0.7px;

    @media only screen and (max-width: 1024px) {
      font-size: 48px;
      width: 280px;
      line-height: 50.4px;
      letter-spacing: -0.48px;
    }

    //mobile/tablet
    @media only screen and (max-width: 980px) {
      width: 100%;
      font-size: 70px;
      line-height: 73.5px;
      letter-spacing: -0.7px;
    }

    //mobile
    @media only screen and (max-width: 480px) {
      width: 313px;
      height: 108px;
      font-size: 46px;
      line-height: 54.28px;
      letter-spacing: -0.46px;
    }

    //shorter screens
    @media only screen and (max-height: 800px) {
      margin-top: 7vh;
      margin-bottom: 5vh;
    }
  }

  p {
    margin: 32px 0;
  }

  form {
    a {
      color: ${colors.mediumGreenhouseGreen};
    }
  }
`;

export const Description = styled.p`
  margin: 10px auto;
  font-size: 16px;
  letter-spacing: 0.5px;

  //mobile/tablet
  @media only screen and (max-width: 980px) {
    margin: 20px auto;
  }

  //mobile
  @media only screen and (max-width: 480px) {
    margin: 25px auto;
  }
`;

export const Input = styled.input`
  && {
    display: flex;
    font-size: 19px;
    border: 2px solid ${colors.lightGrey};
    border-radius: 8px;
    padding: 0 20px;
    height: 60px;
    width: 100%;
    max-width: 480px;
    gap: 8px;

    @media only screen and (max-width: 980px) {
      margin: 32px 0 10px;
    }
  }
  ::placeholder {
    color: ${PrimaryPalette.darkestGrey};
    font-size: 19px;
    font-weight: 400;
    line-height: 32.3px;
    height: 32px;
  }
`;

export const StyledButton = styled.button.attrs({
  className: "button-primary",
})`
  flex: 0 0 auto;
  letter-spacing: 0;
  font-size: 15px;
  line-height: 24px;
  border-radius: 12px;
  gap: 8px;
  width: 100px;
  min-width: 100px;
  height: 46px;
  &&:active,
  &&:hover {
    border-color: #2e65ba !important;
    background-color: #2e65ba !important;
    box-shadow: 0 0 0 0 !important;
  }

  //mobile
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
`;

export const GoogleButton = styled.button`
  display: flex;
  justify-content: center;
  font-size: 15px;
  cursor: pointer;
  gap: 8px;
  &&:active,
  &&:hover {
    background-color: ${PrimaryPalette.mediumGrey};
  }

  color: ${PrimaryPalette.lightEvergreen};
  background-color: #f5f5f5;
  width: 219px;
  height: 46px;
  padding: 0 28px;
  border-radius: 12px;
  border: none;
  align-items: center;

  //mobile
  @media only screen and (max-width: 480px) {
    width: 100%;
    height: 44px;
    margin-top: 20px;
  }
`;

export const LongerStyledButton = styled(StyledButton)`
  margin-top: 32px;

  //desktop.tablet
  @media only screen and (min-width: 480px) {
    && {
      width: auto;
      padding: 10px 28px;
    }
  }
`;
