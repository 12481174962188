import React from "react";
import { Button, FormControl, FormGroup } from "react-bootstrap";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  FormContainer,
  Description,
  Input,
  LongerStyledButton,
  StyledButton,
} from "./styles";
import HiddenCsrfInput from "./hidden_csrf_input";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      login: "",
    };
  }

  onChange = (event) => {
    this.setState({ login: event.target.value });
  };

  render() {
    return (
      <FormContainer title="Forgot Your Password?" id="forgot-password">
        <div className="header-text">
          <h2>Forgot your password?</h2>
        </div>
        <Description>
          Enter your email address and we’ll send you instructions for resetting
          your password.
        </Description>

        <StyledForm action={this.props.form_action_path} method="post">
          <FormGroup controlId="login" bsSize="large">
            <Input
              type="text"
              placeholder="Email Address"
              aria-label="Email Address"
              value={this.state.login}
              onChange={this.onChange}
              name="user[login]"
              required
              autoFocus
            />
          </FormGroup>

          <FormGroup style={{ marginTop: "0px" }}>
            <LongerStyledButton type="submit">
              Send reset instructions
            </LongerStyledButton>
          </FormGroup>

          <HiddenCsrfInput />
        </StyledForm>
      </FormContainer>
    );
  }
}

const StyledForm = styled.form`
  margin-top: 24px;
`;

ForgotPassword.propTypes = {
  form_action_path: PropTypes.string.isRequired,
};

export default ForgotPassword;
