import React from "react";
import { ControlLabel, FormControl, FormGroup } from "react-bootstrap";
import Csrf from "utilities/csrf";
import request from "helpers/request";
import PropTypes from "prop-types";
import Flash from "utilities/flash";
import styled from "styled-components";
import { FormContainer, Input, StyledButton, GoogleButton } from "./styles";
import GoogleButtonIcon from "WebpackRoot/components/login_flow/google_icon.svg";

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      login: this.props.login,
      disableNextButton: false,
    };

    this.requestThrottled = 429;
  }

  onChange = (event) => {
    this.setState({
      login: event.target.value,
      disableNextButton: false,
    });
  };

  validateLogin = (event) => {
    event.preventDefault();

    request({
      url: this.props.validate_login_path,
      method: "get",
      params: {
        login: this.state.login,
      },
    })
      .then((request, _event) => {
        let response = request.data;

        if (response.disable_next_button) {
          this.setState({ disableNextButton: true });
        }

        if (response.notice) {
          Flash.set(response.notice, "notice", false);
        }

        if (response.show_password_form) {
          this.props.showPasswordForm(this.state.login, response.saml_path);
        }

        if (response.show_magic_link_notification) {
          this.props.magicLinkNotificationCallback(
            this.state.login,
            response.return_to
          );
        }

        if (response.redirect_to) {
          window.location = response.redirect_to;
        }
      })
      .catch((request) => {
        if (request.response.data.error) {
          Flash.setError(request.response.data.error);
        } else if (request.response.status === this.requestThrottled) {
          Flash.setError(
            "Sorry, you have made too many requests. Please try again later."
          );
        } else {
          Flash.setError("Sorry, we encountered an error. Please try again.");
        }
      });
  };

  render() {
    return (
      <FormContainer id="email-form">
        <div className="header-text">
          <h2>On board and all-together.</h2>
        </div>
        <StyledForm id="validate-login" onSubmit={this.validateLogin}>
          <FormGroup controlId="email" bsSize="large">
            <Input
              type="input"
              placeholder="Email"
              aria-label="Email"
              name="email"
              value={this.state.login}
              onChange={this.onChange}
              autoFocus
              required
            />
          </FormGroup>

          <FlexContainer>
            <FormGroup>
              <StyledButton
                type="submit"
                disabled={this.state.disableNextButton}
              >
                Next
              </StyledButton>
            </FormGroup>

            <FormGroup style={{ marginTop: "0px" }}>
              <GoogleButton
                type="submit"
                form="google-sign-in"
                aria-label="Sign In With Google"
              >
                <img
                  src={GoogleButtonIcon}
                  alt="Google-icon"
                  style={{ width: "20px", height: "20px" }}
                />
                Sign in with Google
              </GoogleButton>
            </FormGroup>
          </FlexContainer>
        </StyledForm>
        <form
          action={this.props.sign_in_with_google_path}
          id="google-sign-in"
          method="post"
        >
          <input type="hidden" value={Csrf.token()} name="authenticity_token" />
        </form>
      </FormContainer>
    );
  }
}

const StyledForm = styled.form`
  position: relative;
  margin-top: 40px;

  //shorter screens
  @media only screen and (max-height: 800px) {
    margin-top: 0;
  }

  @media only screen and (max-width: 980px) {
    margin: 0;
  }

  @media only screen and (max-width: 480px) {
    width: 100%;
    margin: 0;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 32px 0;
  width: 100%;
  & > * > * {
    margin-right: 32px;
  }

  //mobile
  @media only screen and (max-width: 480px) {
    display: block;
  }
`;

LoginForm.propTypes = {
  login: PropTypes.string,
  validate_login_path: PropTypes.string.isRequired,
  sign_in_with_google_path: PropTypes.string.isRequired,
  showPasswordForm: PropTypes.func.isRequired,
  magicLinkNotificationCallback: PropTypes.func.isRequired,
};

export default LoginForm;
